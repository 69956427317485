<template>
  <AuthWrap :title="$t('register.signIn')" :text="$t('register.enterOwnData')">
    <PrimaryInput
      iconPath="M15.3798 11.8121C16.1138 10.823 16.377 9.78052 15.5469 8.86035C13.684 6.25372 12.4312 4.64178 11.6454 3.86551C10.1514 2.38972 7.69384 2.58934 6.39996 3.86425C5.705 4.54901 5.46973 4.78416 4.75617 5.50894C0.781495 9.48575 3.20546 17.8926 9.64922 24.3427C16.0915 30.7913 24.4976 33.2166 28.4806 29.2314C29.139 28.5966 29.6965 28.0388 30.1362 27.5741C31.4042 26.2339 31.5967 23.8847 30.1279 22.3585C29.3748 21.5761 27.8377 20.3837 25.1214 18.4407C24.2895 17.6968 23.3031 17.8581 22.3987 18.4514C21.9637 18.7368 21.6471 19.0255 21.0494 19.6238L19.9639 20.7099C19.8209 20.853 17.8795 19.8807 15.9949 17.9943C14.1094 16.1069 13.1378 14.1654 13.2801 14.0231C13.5735 13.7297 13.9356 13.3674 14.3663 12.9363C14.556 12.7464 14.6467 12.6545 14.763 12.5323C15.0052 12.2777 15.2058 12.0466 15.3798 11.8121ZM21.9662 22.7134L23.0519 21.6271C23.3809 21.2978 23.5942 21.0953 23.7676 20.9557C26.1471 22.6645 27.5252 23.7389 28.0881 24.3238C28.426 24.675 28.3735 25.3157 28.0797 25.6262C27.6732 26.056 27.1449 26.5845 26.4967 27.2097C23.9212 29.7864 17.1356 27.8286 11.652 22.3396C6.16671 16.8489 4.20992 10.0624 6.76603 7.50485C7.47672 6.78305 7.70189 6.558 8.38692 5.88301C8.64377 5.62992 9.34341 5.57309 9.65586 5.88175C10.2609 6.4794 11.3832 7.9164 13.0341 10.2161C12.9486 10.3222 12.8419 10.4421 12.712 10.5787C12.6166 10.6789 12.5372 10.7594 12.3635 10.9332C11.9331 11.364 11.5714 11.726 11.2783 12.019C9.43222 13.865 11.0044 17.0068 13.9921 19.9974C16.9778 22.986 20.1207 24.5599 21.9662 22.7134Z"
      class="input"
      :errText="
        error
          ? $t('numberPhone.phone_already_exists')
          : errorsPhone.phone
          ? $t('register.errorPhone')
          : ''
      "
      :success="success.phone"
    >
      <input
        v-model="phone"
        @focus="resetInput"
        @blur="success.phone = !checkPhone(phone) && phone.length == 13"
        maxlength="13"
        type="text"
        class="border-input"
        :placeholder="$t('register.phone')"
      />
    </PrimaryInput>
    <PrimaryInput
      class="input"
      iconPath="M15.3798 11.8121C16.1138 10.823 16.377 9.78052 15.5469 8.86035C13.684 6.25372 12.4312 4.64178 11.6454 3.86551C10.1514 2.38972 7.69384 2.58934 6.39996 3.86425C5.705 4.54901 5.46973 4.78416 4.75617 5.50894C0.781495 9.48575 3.20546 17.8926 9.64922 24.3427C16.0915 30.7913 24.4976 33.2166 28.4806 29.2314C29.139 28.5966 29.6965 28.0388 30.1362 27.5741C31.4042 26.2339 31.5967 23.8847 30.1279 22.3585C29.3748 21.5761 27.8377 20.3837 25.1214 18.4407C24.2895 17.6968 23.3031 17.8581 22.3987 18.4514C21.9637 18.7368 21.6471 19.0255 21.0494 19.6238L19.9639 20.7099C19.8209 20.853 17.8795 19.8807 15.9949 17.9943C14.1094 16.1069 13.1378 14.1654 13.2801 14.0231C13.5735 13.7297 13.9356 13.3674 14.3663 12.9363C14.556 12.7464 14.6467 12.6545 14.763 12.5323C15.0052 12.2777 15.2058 12.0466 15.3798 11.8121ZM21.9662 22.7134L23.0519 21.6271C23.3809 21.2978 23.5942 21.0953 23.7676 20.9557C26.1471 22.6645 27.5252 23.7389 28.0881 24.3238C28.426 24.675 28.3735 25.3157 28.0797 25.6262C27.6732 26.056 27.1449 26.5845 26.4967 27.2097C23.9212 29.7864 17.1356 27.8286 11.652 22.3396C6.16671 16.8489 4.20992 10.0624 6.76603 7.50485C7.47672 6.78305 7.70189 6.558 8.38692 5.88301C8.64377 5.62992 9.34341 5.57309 9.65586 5.88175C10.2609 6.4794 11.3832 7.9164 13.0341 10.2161C12.9486 10.3222 12.8419 10.4421 12.712 10.5787C12.6166 10.6789 12.5372 10.7594 12.3635 10.9332C11.9331 11.364 11.5714 11.726 11.2783 12.019C9.43222 13.865 11.0044 17.0068 13.9921 19.9974C16.9778 22.986 20.1207 24.5599 21.9662 22.7134Z"
      :success="success.home_phone"
    >
      <input
        v-model="home_phone"
        @focus="home_phone = phoneMask"
        @blur="
          success.home_phone =
            !checkPhone(home_phone) && home_phone.length == 13
        "
        maxlength="13"
        type="text"
        class="border-input"
        :placeholder="$t('register.notNecessary')"
      />
    </PrimaryInput>
    <PrimaryButton
      style="margin-bottom: 1.875rem"
      :title="$t('buttons.next')"
      @click="handleNext"
    />
    <PrimaryButton
      :title="$t('register.prev')"
      @click="$emit('prev')"
      isOutlined
    />
  </AuthWrap>
</template>

<script>
import { toRefs, reactive } from "vue";
import { useStore } from "vuex";
import AuthWrap from "../AuthWrap.vue";
import PrimaryButton from "../PrimaryButton.vue";
import PrimaryInput from "../PrimaryInput.vue";

export default {
  components: { AuthWrap, PrimaryInput, PrimaryButton },
  emits: ["next"],
  setup(_, { emit }) {
    // setup() {
    const store = useStore();

    const phones = reactive({
      phone: "",
      home_phone: "",
      phoneMask: "+998",
      error: "",
    });

    const errorsPhone = reactive({
      phone: false,
      home_phone: false,
      phoneMask: false,
      error: false,
    });

    const success = reactive({
      phone: false,
      home_phone: false,
    });

    const resetInput = () => {
      phones.phone = phones.phoneMask;
      errorsPhone.phone = false;
      errorsPhone.home_phone = false;
      errorsPhone.phoneMask = false;
      errorsPhone.error = false;
      phones.error = "";
    };

    const checkPhone = (val) => {
      return !/(^[0-9]+$)/.test(val.replace("+", ""));
    };

    const handleNext = () => {
      for (const el in phones) {
        if (phones[el].length > 12) {
          errorsPhone[el] = false;
        } else {
          errorsPhone[el] = true;
        }
        if (el == "home_phone") {
          errorsPhone[el] = true;
        }
      }
      if (!/(^[0-9]+$)/.test(phones.phone.replace("+", ""))) {
        errorsPhone.phone = true;
      } else {
        store.dispatch("register/getUniqueUser", {
          cb: async (data) => {
            const type = await data;
            if (type.error) {
              phones.error = type.error;
              errorsPhone.error = true;
            } else {
              errorsPhone.error = false;
              phones.error = "";
              if (Object.values(errorsPhone).indexOf(true)) {
                if (phones.home_phone.length > 4) {
                  store.commit("register/setUser", {
                    phone: phones.phone,
                    home_phone: phones.home_phone,
                  });
                } else {
                  store.commit("register/setUser", {
                    phone: phones.phone,
                  });
                }
                emit("next");
              }
            }
          },

          phone: { phone: phones.phone },
        });
      }
    };

    return {
      handleNext,
      ...toRefs(phones),
      errorsPhone,
      resetInput,
      checkPhone,
      success,
    };
  },
};
</script>

<style lang="scss" scoped>
.mb-1 {
  margin-bottom: 1em;
}
.fivth-form {
  .label-number {
    display: flex;
    flex-direction: column;
    width: max(380px, 60%);
    label {
      font-weight: 500;
      line-height: 2;
      color: var(--gray-font-color);
    }
    span {
      margin-bottom: 0.5em;
      font-weight: 500;
      color: var(--gray-font-color);
    }
  }
}
@media screen and (max-width: 576px) {
  .fivth-form {
    .label-number {
      width: 100%;
    }
  }
}
</style>
