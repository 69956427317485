<template>
  <AuthWrap :title="$t('register.signIn')" :text="$t('register.createAccount')">
    <form novalidate @submit.prevent="handleClick">
      <PrimaryInput
        :errText="
          error.email
            ? $t('register.errorEmail')
            : unicqUser.error == 'email_already_exists'
            ? $t('register.email_already_exists')
            : unicqUser.error == 'email_invalid' ? $t('register.errorEmail') : ''
        "
        :success="success.email && unicqUser.error == ''"
        class="input"
      >
        <input
          v-model="email"
          @keydown="inputEngText"
          @input="inputEmail(email)"
          @blur="
            (activeHelpEmail = false),
              ((success.email = validateEmail(email)),
              (error.email = !validateEmail(email)))
          "
          @focus="
            () => {
              error.email = false;
              activeHelpEmail = true;
              unicqUser.error = '';
            }
          "
          :placeholder="$t('register.email')"
          type="text"
          class="text-input"
          :class="{
            'error-border':
              error.email || unicqUser.error == 'email_already_exists' ||  unicqUser.error == 'email_invalid',
          }"
        />
      </PrimaryInput>
      <PrimaryInput
        :errText="error.password ? $t('NewRegister.moreSymbol') : ''"
        class="input"
        :success="success.password"
      >
        <input
          v-model="password"
          :placeholder="$t('register.password')"
          @focus="focusPassword"
          type="current-password"
          :blur="(success.password = password.length >= 6)"
          class="text-input"
          :class="{ 'error-border': error.password }"
        />
      </PrimaryInput>
      <PrimaryInput
        :errText="
          error.passwordAgain ? $t('register.errorRepeatePassword') : ''
        "
        :success="success.passwordAgain"
        class="input"
      >
        <input
          v-model="passwordAgain"
          @focus="focusPassword"
          :placeholder="$t('register.repeatePassword')"
          autocomplete="on"
          @blur="
            success.passwordAgain =
              passwordAgain == password && passwordAgain.length != 0
          "
          type="current-password"
          class="text-input"
          :class="{ 'error-border': error.passwordAgain }"
        />
      </PrimaryInput>
      <!-- <div :class="{ activeHelp: activeHelpEmail }" class="help">
        <svg
          width="16"
          height="19"
          class="triangle"
          viewBox="0 0 16 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-5.90104e-07 9.46169L15.75 0.4342L15.75 18.4892L-5.90104e-07 9.46169Z"
            fill="#B5ADEF"
          />
        </svg>

        {{ $t("register.help.email") }} example@gmail.com
      </div> -->
      <div class="space-y">
        <label class="container-checkbox">
          <input v-model="is_korean" type="radio" :value="true" name="korean" />
          <span class="checkmark"></span>
          <span>{{ $t("register.korean") }}</span>
        </label>
        <label class="container-checkbox">
          <input v-model="is_korean" type="radio" :value="false" name="other" />
          <span class="checkmark"></span>
          <span>{{ $t("register.other") }}</span>
        </label>
      </div>

      <PrimaryButton
        :style="{ margin: '1.875rem 0' }"
        :title="$t('buttons.next')"
      />
    </form>
    <PrimaryButton
      :title="$t('register.prev')"
      @click="$emit('prev')"
      isOutlined
    />

    <span v-if="serverErr" class="no-valid" style="margin-left: 1rem">{{
      $t("register.errServer")
    }}</span>
  </AuthWrap>
</template>

<script>
import { i18n } from "@/i18n";
import { getUniqueUser } from "@/network/v1/index";
import { computed, reactive, ref, toRefs } from "vue";
import { useStore } from "vuex";
import { useLanguage } from "@/utils/language";
import { useFormatter } from "@/utils/formatter";
import AuthWrap from "../AuthWrap.vue";
import PrimaryInput from "../PrimaryInput.vue";
import PrimaryButton from "../PrimaryButton.vue";

export default {
  emits: ["next, prev"],
  components: { AuthWrap, PrimaryInput, PrimaryButton },
  setup(_, { emit }) {
    //   setup() {
    const checkedForm = ref(false);
    const { validateEngSpace, validateKor } = useFormatter();
    // const sixtyYearOld = 504921600000;
    const inputEngText = (val) => {
      if (!validateEngSpace(val.key)) {
        val.preventDefault();
        return;
      }
    };

    const store = useStore();
    const user = computed(() => store.getters["register/getUser"]);

    const is_korean = ref(false);

    const serverErr = ref(false);
    const activeHelp = ref(false);
    const activeHelpEmail = ref(false);

    const info = reactive({
      email: "",
      password: "",
      passwordAgain: "",
    });

    const unicqUser = reactive({});
    const errorForm = ref(false);

    const error = reactive({
      email: false,
      password: false,
      passwordAgain: false,
    });

    const success = reactive({
      email: false,
      password: false,
      passwordAgain: false,
    });

    const focusPassword = () => {
      error.password = false;
      error.passwordAgain = false;
    };

    function validateEmail(elementValue) {
      /* eslint-disable*/
      const emailRe =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      /* eslint-enable */
      return emailRe.test(elementValue);
    }

    const inputEmail = (val) => {
      if (/(\s)/.test(val)) {
        info.email = val.replace(/([\s])/, "");
      }
    };

    const handleCorrectInput = () => {
      for (const el in info) {
        if (info[el]) {
          error[el] = false;
        } else {
          error[el] = true;
        }
        if (info.password.length < 6) {
          error.password = true;
        }
        if (info.password !== info.passwordAgain) {
          error.passwordAgain = true;
        }
      }
      error.email = !validateEmail(info.email);
    };

    const handleClick = () => {
      errorForm.value = false;

      handleCorrectInput();
      fetchData();
    };

    const fetchData = () => {
      serverErr.value = false;

      unicqUser.error = "";
      if (Object.values(error).indexOf(true) == -1) {
        getUniqueUser(
          async (res) => {
            const isUniq = await res;
            if (isUniq.error) {
              for (const name in isUniq) {
                unicqUser[name] = isUniq[name];
              }
              
              return;
            }

            store.commit("register/setUser", {
              email: info.email,
              password: info.password,
              is_korean: is_korean.value,
            });
            if (user.value.uzbek_citizen) {
              emit("next");
            } else {
              emit("next", 2);
            }
          },
          {
            email: info.email,
          },
          async () => {
            serverErr.value = true;
          }
        );
      }
    };

    return {
      i18n,
      handleClick,
      checkedForm,
      ...toRefs(info),
      unicqUser,
      error,
      is_korean,
      serverErr,
      validateEngSpace,
      validateEmail,
      validateKor,
      inputEngText,
      inputEmail,
      activeHelp,
      success,
      activeHelpEmail,
      ...useLanguage(),
      errorForm,
      focusPassword,
    };
  },
};
</script>

<style lang="scss" scoped>
.standart-link {
  color: blue;
}
.relative {
  position: relative;
  .triangle {
    position: absolute;
    // transform: rotate(-90deg);
    // bottom: -15px;
    top: 10px;
    left: -15px;
  }

  .help {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    // bottom: 5rem;
    top: 0;
    left: 105%;
    padding: 1em;
    width: 15vw;
    color: var(--secondary-font-color);
    background: linear-gradient(
      90deg,
      rgba(69, 3, 98, 0.5) -0.58%,
      rgba(111, 101, 235, 0.5) -0.58%,
      rgba(51, 4, 92, 0.5) 91.62%,
      rgba(75, 0, 130, 0.5) 100%
    );
    backdrop-filter: blur(19.7186px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 14.7889px;
    transition: all 0.4s;
    &.activeHelp {
      opacity: 1;
      visibility: visible;
    }
  }
}

.link {
  color: #6f65eb;
}

.container-checkbox {
  display: block;
  position: relative;
  // padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1.2em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  color: var(--disabled-color);

  input {
    width: 1.4375rem;
    height: 1.4375rem;
    cursor: pointer;
    margin-right: 0.875rem;
  }
}

input:checked ~ .container-checkbox {
  color: var(--primary-color);
}

.sub-title {
  color: var(--gray-font-color);
  // white-space: nowrap;
  margin-bottom: 0.5em;
}
.born {
  font-size: 1.4em;
  font-weight: 500;
}
.space-y {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.container-data {
  display: flex;
  gap: 2.2em;
  label {
    font-weight: 500;
    color: #a5a5a5;
    line-height: 2;
  }

  &__item {
    // flex: 0 0 33%;
    display: flex;
    flex-direction: column;
    &:last-child {
      margin-left: 2.2em;
    }
  }
}
.agreement {
  margin-top: 1.5em;
  font-size: 1rem;
  #checkbox {
    margin-right: 0.5em;
  }
}
@media screen and (max-width: 1200px) {
  .sub-title {
    margin-bottom: 2em;
    // white-space: nowrap;
  }
  .space-y {
    margin-top: 0;
    margin-bottom: 2em;
    .container-checkbox {
      margin-bottom: 1em;
    }
  }

  .input-container {
    // width: 100%;
    .text-input {
      margin-bottom: 1em;
    }
  }
  .born {
    margin-bottom: 1em;
  }
  .agreement {
    margin-top: 3em;
    margin-bottom: 1.5em;
  }
}

@media screen and (max-width: 767px) {
  .agreement {
    margin-bottom: 0;
  }
  .container-data {
    gap: 1rem;
  }
  .relative {
    position: relative;
    .triangle {
      position: absolute;
      bottom: -17px;
      top: auto;
      left: 50%;
      transform: translateX(-50%) rotate(-90deg);
      z-index: 0;
    }
    .help {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      bottom: 7rem;
      top: auto;
      left: 0;
      padding: 1em;
      width: 95%;
      color: var(--secondary-font-color);
      background: linear-gradient(
        90deg,
        rgba(69, 3, 98, 0.5) -0.58%,
        rgba(111, 101, 235, 0.5) -0.58%,
        rgba(51, 4, 92, 0.5) 91.62%,
        rgba(75, 0, 130, 0.5) 100%
      );
      backdrop-filter: blur(19.7186px);
      /* Note: backdrop-filter has minimal browser support */
      border-radius: 14.7889px;
      transition: all 0.4s;
      z-index: 2;
      &.activeHelp {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .relative {
    position: relative;
    .triangle {
      position: absolute;
      bottom: -17px;
      top: auto;
      left: 50%;
      transform: translateX(-50%) rotate(-90deg);
      z-index: 0;
    }
    .help {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      bottom: 7rem;
      top: auto;
      left: 0;
      padding: 1em;
      width: 95%;
      color: var(--secondary-font-color);
      background: linear-gradient(
        90deg,
        rgba(69, 3, 98, 0.5) -0.58%,
        rgba(111, 101, 235, 0.5) -0.58%,
        rgba(51, 4, 92, 0.5) 91.62%,
        rgba(75, 0, 130, 0.5) 100%
      );
      backdrop-filter: blur(19.7186px);
      /* Note: backdrop-filter has minimal browser support */
      border-radius: 14.7889px;
      transition: all 0.4s;
      z-index: 2;
      &.activeHelp {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
</style>
