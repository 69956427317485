<template>
  <AuthWrap :title="$t('register.signIn')" :text="$t('register.documents_data')">
    <form @submit.prevent="nextForm">
      <div class="typeDoc">
        <PrimarySelect
          iconPath="M7.08333 1.41699H21.8368L29.75 9.33019V29.7503C29.75 31.3151 28.4815 32.5837 26.9167 32.5837H7.08333C5.51853 32.5837 4.25 31.3151 4.25 29.7503V4.25033C4.25 2.68552 5.51853 1.41699 7.08333 1.41699ZM18.4167 4.25033H7.08333V29.7503H26.9167V12.7503H21.25C19.6852 12.7503 18.4167 11.4818 18.4167 9.91699V4.25033ZM21.25 4.83713V9.91699H26.3299L21.25 4.83713ZM11.3333 24.0837V21.2503H19.8333V24.0837H11.3333ZM11.3333 15.5837V18.417H22.6667V15.5837H11.3333Z"
          class="input"
          :success="success.typeDoc"
        >
          <select
            v-model="info.typeDoc"
            @focus="error.typeDoc = false"
            name="typeDoc"
            @blur="success.typeDoc = info.typeDoc != ''"
          >
            <option disabled value="">{{ $t("NewRegister.TypeDoc") }}</option>
            <option
              :value="item"
              v-for="(item, index) in new Date() - new Date(user.birth_date) >
              seventeen
                ? listTypeDoc
                : metrika"
              :key="index"
            >
              {{ $t(`NewRegister.listTypeDoc.${item}`) }}
            </option>
          </select>
        </PrimarySelect>
      </div>
      <transition name="fade">
        <div v-if="info.typeDoc">
          <div class="relative">
            <HelpWrap :class="{ activeHelp: helperPassport }">
              <img
                v-if="
                  (locale === 'uz' || locale === 'ru' || locale === 'ko') &&
                  new Date() - new Date(user.birth_date) > seventeen &&
                  info.typeDoc != 'international'
                "
                :src="
                  require(`@/assets/img/doc__${locale}__${info.typeDoc}.png`)
                "
                alt="Passport"
              />
              <span
                v-if="
                  info.typeDoc == 'international' ||
                  new Date() - new Date(user.birth_date) < seventeen
                "
              >
                {{ $t("NewRegister.warning") }}
              </span>
            </HelpWrap>
            <PrimaryInput
              :errText="
                error.id_doc
                  ? $t('register.errorEmail')
                  : unicqUser.error == 'id_doc_already_exists'
                  ? $t('register.id_doc_already_exists')
                  : ''
              "
              class="input"
              :success="success.id_doc"
            >
              <input
                name="id_doc"
                @input="inputID(info.id_doc)"
                @blur="
                  (helperPassport = false),
                    (success.id_doc = checkId(info.id_doc))
                "
                @focus="
                  () => {
                    helperPassport = true;
                    error.id_doc = false;
                    unicqUser.error = '';
                  }
                "
                v-model="info.id_doc"
                maxlength="9"
                :placeholder="
                  info.typeDoc == 'passport'
                    ? $t('NewRegister.passportNumber')
                    : info.typeDoc == 'metrika'
                    ? $t('NewRegister.numberMetrik')
                    : info.typeDoc == 'international'
                    ? $t('NewRegister.internationalNumber')
                    : info.typeDoc == 'id_doc'
                    ? $t('NewRegister.idNumber')
                    : 's'
                "
                type="text"
                class="border-input bold"
              />
            </PrimaryInput>
          </div>

          <div class="relative">
            <HelpWrap :class="{ activeHelp: activeHelp }">
              <img
                v-if="
                  (locale === 'uz' || locale === 'ru' || locale === 'ko') &&
                  new Date() - new Date(user.birth_date) > seventeen &&
                  info.typeDoc != 'international'
                "
                :src="
                  require(`@/assets/img/pinfl__${locale}__${info.typeDoc}.png`)
                "
                :alt="info.typeDoc"
              />
              <span
                v-if="
                  info.typeDoc == 'international' ||
                  new Date() - new Date(user.birth_date) < seventeen
                "
              >
                {{ $t("NewRegister.warning") }}
              </span>
            </HelpWrap>
            <PrimaryInput
              v-if="new Date() - new Date(user.birth_date) > seventeen"
              :errText="
                error.pinfl
                  ? $t('register.errorEmail')
                  : unicqUser.error == 'pinfl_already_exists'
                  ? $t('NewRegister.pinfl_already_exists')
                  : ''
              "
              class="input"
            >
              <input
                name="id_doc"
                @input="inputPinfl(info.pinfl)"
                @blur="activeHelp = false"
                @focus="
                  () => {
                    activeHelp = true;
                    error.pinfl = false;
                  }
                "
                v-model="info.pinfl"
                maxlength="14"
                :placeholder="$t('NewRegister.pinfl')"
                type="text"
                class="border-input bold"
                :class="{
                  'error-border': error.pinfl,
                }"
              />
            </PrimaryInput>
          </div>
        </div>
      </transition>
      <PrimaryButton
        style="margin-bottom: 1.875rem"
        :title="$t('buttons.next')"
      />
    </form>
    <PrimaryButton
      :title="$t('register.prev')"
      @click="$emit('prev')"
      isOutlined
    />
  </AuthWrap>
</template>

<script>
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { getUniqueUser } from "@/network/v1/index";
import { useLanguage } from "@/utils/language";
import AuthWrap from "../AuthWrap.vue";
import PrimaryInput from "../PrimaryInput.vue";
import PrimaryButton from "../PrimaryButton.vue";
import PrimarySelect from "../PrimarySelect.vue";
import HelpWrap from "../HelpWrap.vue";

export default {
  components: {
    AuthWrap,
    PrimaryInput,
    PrimaryButton,
    PrimarySelect,
    HelpWrap,
  },
  setup(_, { emit }) {
    // setup() {
    const { i18n } = useLanguage();
    const locale = i18n.global.locale.value;

    const store = useStore();
    const user = computed(() => store.getters["register/getUser"]);
    // const seventeen = 536468184000;
    const seventeen = 567648000000;
    const listTypeDoc = ["passport", "id_doc", "international"];
    const metrika = ["metrika"];

    const activeHelp = ref(false);
    const helperPassport = ref(false);

    const info = reactive({
      typeDoc: "",
      id_doc: "",
      pinfl: "",
    });

    const unicqUser = reactive({});

    const error = reactive({
      typeDoc: false,
      id_doc: false,
      pinfl: false,
      id_doc_no_correct: false,
    });

    const success = reactive({
      typeDoc: false,
      id_doc: false,
      pinfl: false,
    });

    const checkId = (val) => {
      const str = val.substr(0, 2);
      const number = val.substr(2, 9);

      return (
        /^\d{1,7}/.test(number) &&
        /(^[a-zA-Z]{1,2}$)/.test(str) &&
        info.id_doc.length == 9
      );
    };

    // а)метрика, б)паспорт, в) загранпаспорт, г) IDкарта
    const inputID = (val) => {
      // info.id_doc = val.replace(/([^a-zA-Z0-9])/, "");
      const str = val.substr(0, 2);
      const number = val.substr(2, 9);
      info.id_doc = val.replace(/([^a-zA-Z\\s])+([^0-9\\s]$)/, "");

      if (
        /^\d{1,7}/.test(number) &&
        /(^[a-zA-Z]{1,2}$)/.test(str) &&
        info.id_doc.length == 9
      ) {
        error.id_doc_no_correct = false;
        error.id_doc = false;
      } else {
        error.id_doc_no_correct = true;
      }
    };
    const inputPinfl = (val) => {
      info.pinfl = val.replace(/([^\d])/, "");
    };

    onMounted(() => {
      store.commit("register/deleteKeyUser", "id_doc");
      store.commit("register/deleteKeyUser", "pinfl");
    });

    const nextForm = () => {
      for (const el in info) {
        if (info[el]) {
          error[el] = false;
        } else {
          error[el] = true;
        }
      }
      if (info.id_doc.length != 9) {
        error.id_doc = true;
        return;
      }
      if (
        info.pinfl.length != 14 &&
        new Date() - new Date(user.value.birth_date) > seventeen
      ) {
        error.pinfl = true;
        return;
      }
      if (info.typeDoc.length < 1) {
        error.typeDoc = true;
        return;
      }
      if (error.id_doc_no_correct == true) {
        return;
      }
      // setDocType
      store.commit("register/setDocType", info.typeDoc);
      if (new Date() - new Date(user.value.birth_date) > seventeen) {
        getUniqueUser(
          async (res) => {
            const isUniq = await res;
            if (isUniq.error) {
              for (const name in isUniq) {
                unicqUser[name] = isUniq[name];
              }
              return;
            }
            store.commit("register/setUser", {
              id_doc: info.id_doc.toUpperCase(),
              pinfl: info.pinfl.toUpperCase(),
            });
            emit("next");
          },
          {
            id_doc: info.id_doc.toUpperCase(),
            pinfl: info.pinfl.toUpperCase(),
          },
          async (e) => {
            console.log(await e);
          }
        );
      } else {
        getUniqueUser(
          async (res) => {
            const isUniq = await res;
            if (isUniq.error) {
              for (const name in isUniq) {
                unicqUser[name] = isUniq[name];
              }
              return;
            }
            store.commit("register/setUser", {
              id_doc: info.id_doc.toUpperCase(),
            });
            emit("next");
          },
          {
            id_doc: info.id_doc.toUpperCase(),
          },
          async (e) => {
            console.log(await e);
          }
        );
      }
    };

    return {
      listTypeDoc,
      error,
      info,
      user,
      metrika,
      seventeen,
      activeHelp,
      checkId,
      helperPassport,
      inputID,
      inputPinfl,
      nextForm,
      unicqUser,
      locale,
      success,
    };
  },
};
</script>

<style lang="scss" scoped>
img {
  background: transparent;
}
.bold {
  font-weight: 500;
}
label {
  margin-bottom: 1rem;
}
.typeDoc {
  margin-top: 2rem;
  .text-input {
    width: 60%;
    padding-right: 6em;
  }
}
.passport {
  display: flex;
  flex-direction: column;
  width: min-content;
  input {
    text-transform: uppercase;
    width: max-content;
  }
}
.relative {
  position: relative;
}
@media screen and (max-width: 767px) {
  .relative {
    position: relative;
  }
  .typeDoc {
    margin-top: 2rem;
    .text-input {
      width: 100%;
      padding-right: 6em;
    }
  }
  .passport {
    width: 100%;
    input {
      width: auto;
    }
  }
}
</style>
