<template>
  <div class="reg">
    <div v-if="loading">
      <Loader />
    </div>
    <div style="width: 100%; height: 100%" v-else>
      <div v-if="!isRegister" class="no-register">
        <div class="container">
          <svg
            width="9rem"
            height="10rem"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.791748 10.0003C0.791748 14.8098 4.6906 18.7087 9.50008 18.7087C14.3096 18.7087 18.2084 14.8098 18.2084 10.0003C18.2084 5.19085 14.3096 1.29199 9.50008 1.29199C4.6906 1.29199 0.791748 5.19085 0.791748 10.0003ZM16.6251 10.0003C16.6251 13.9354 13.4351 17.1253 9.50008 17.1253C5.56505 17.1253 2.37508 13.9354 2.37508 10.0003C2.37508 6.0653 5.56505 2.87533 9.50008 2.87533C13.4351 2.87533 16.6251 6.0653 16.6251 10.0003ZM10.2946 11.5823H11.0858V13.1657H7.91909V11.5823H8.71075V9.99902H7.91909V8.41568H10.2946V11.5823ZM10.2923 6.83235C10.2923 7.26958 9.93771 7.62402 9.50034 7.62402C9.06297 7.62402 8.70841 7.26958 8.70841 6.83235C8.70841 6.39512 9.06297 6.04068 9.50034 6.04068C9.93771 6.04068 10.2923 6.39512 10.2923 6.83235Z"
              fill="#F43834"
            />
          </svg>

          <div>{{ $t("register.noRegister") }}</div>
        </div>
      </div>
      <div v-else>
        <transition name="fade-form" mode="out-in">
          <BornForm
            v-if="currentForm == 0"
            class="FirstForm"
            @next="incrementCurrentForm"
          />
        </transition>

        <transition name="fade-form" mode="out-in">
          <EmailForm
            class="ThirdForm"
            v-show="currentForm == 1"
            @next="incrementCurrentForm"
            @prev="currentForm = 0"
          />
        </transition>
        <transition name="fade-form" mode="out-in">
          <TypeDocForm
            class="SecondForm"
            v-if="currentForm == 2"
            @next="incrementCurrentForm"
            @prev="currentForm = 1"
          />
        </transition>

        <transition name="fade-form" mode="out-in">
          <PersonInfoForm
            class="ThirdForm"
            v-show="currentForm == 3"
            @next="incrementCurrentForm"
            @prev="currentForm = 2"
          />
        </transition>
        <transition name="fade-form" mode="out-in">
          <AddressForm
            class="FourthForm"
            v-show="currentForm == 4"
            @next="incrementCurrentForm"
            @prev="currentForm = 3"
          />
        </transition>

        <transition name="fade-form" mode="out-in">
          <NumberForm
            class="FivthForm"
            v-show="currentForm == 5"
            @next="incrementCurrentForm"
            @prev="currentForm = 4"
          />
        </transition>

        <transition name="fade-form" mode="out-in">
          <FotoFileForm
            class="SevenForm"
            v-show="currentForm == 6"
            @next="incrementCurrentForm"
            @prev="currentForm = 5"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import BornForm from "@/components/register/BornForm.vue";
import AddressForm from "@/components/register/AddressForm.vue";
import NumberForm from "@/components/register/NumberForm.vue";
import FotoFileForm from "@/components/register/FotoFileForm.vue";
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import Loader from "@/components/loader.vue";
import TypeDocForm from "@/components/register/TypeDocForm.vue";
import EmailForm from "@/components/register/EmailForm.vue";
import PersonInfoForm from "@/components/register/PersonInfoForm.vue";
export default {
  components: {
    BornForm,
    AddressForm,
    NumberForm,
    FotoFileForm,
    Loader,
    TypeDocForm,
    EmailForm,
    PersonInfoForm,
  },
  setup() {
    const currentForm = ref(0);
    const store = useStore();
    const isRegister = ref(false);
    const loading = ref(true);

    const incrementCurrentForm = (to = 1) => {
      if (currentForm.value < 0) {
        currentForm.value = 0;
      } else if (currentForm.value > 6) {
        currentForm.value = 6;
      } else {
        currentForm.value = currentForm.value + to;
      }
    };

    const fetchData = () => {
      loading.value = true;
      store.dispatch("period/getRegisterPeriod", {
        cb: (data) => {
          isRegister.value = true;
          const startDate = data.start_date;
          loading.value = false;
          if (data.error) {
            isRegister.value = false;
          }
          if (new Date(startDate) - new Date() < 0) {
            store.dispatch(
              "register/getAllLabel",
              "doc_type,employment,citizenship,nationality,region,country,purpose,inform_source"
            );
          }
        },
      });
    };

    fetchData();

    return {
      currentForm,
      isRegister,
      incrementCurrentForm,
      loading,
    };
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}

.FirstForm,
.SecondForm,
.ThirdForm,
.FourthForm,
.FivthForm,
.SixForm {
  width: 100%;
}

.reg {
  height: 100%;
  :deep() {
    .input {
      margin-bottom: 1.875rem;
    }
  }
}

.no-register {
  font-size: 2em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 4rem;
  color: var(--dark-blue);
}
@media screen and (max-width: 1500px) {
  .grid {
    font-size: 14px;
  }
}
@media screen and (max-width: 1200px) {
  .grid {
    font-size: 12px;
    display: block;
    flex-direction: column-reverse;
    margin: 0 auto;
    :deep() {
      p,
      label,
      select,
      input {
        font-size: 1.3125em;
      }
      h2 {
        font-size: 2.2em;
        line-height: 2;
        font-weight: 500;
      }
    }
    &__img {
      display: none;
      margin: 0 auto;
      max-width: 575px;
      .img {
        position: static;
        width: 100%;
      }
    }
    &__form {
      position: relative;
    }
  }
}

@media screen and (max-width: 576px) {
  .FirstForm {
    font-size: 14px;
  }
  .grid {
    font-size: 12px;
    display: block;
    // flex-direction: column-reverse;
    margin: 0 auto;
    width: 90%;

    &__img {
      display: none;
      // margin: 0 auto;
      margin-left: auto;
      margin-top: 0;
      margin-right: 0;
      max-width: 200px;
      .img {
        position: static;
        width: 100%;
      }
    }
    &__form {
      position: relative;
      .input-container {
        width: 100%;
      }
    }
  }
}

.fade-form-enter-active {
  transition: all 0.6s ease !important;
}

.fade-form-enter-from {
  opacity: 0;
}
</style>
